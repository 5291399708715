// import {hydrate} from "react-dom";
// import "./i18n.js";
// import 'react-dates/lib/initialize.js';
// import "react-dates/lib/css/_datepicker.css";
// import { HelmetProvider } from 'react-helmet-async';
// // import "rc-slider/assets/index.css";
// import "./styles/index.scss";
// import "./index.css";
// import "./fonts/line-awesome-1.3.0/css/line-awesome.min.css";
// //
// import reportWebVitals from "./reportWebVitals.js";
// import  { lazy, Suspense } from "react";

// //
// // import "moment/min/locales.min.js";
// import "moment/locale/ar-dz";
// import "moment/locale/ar-kw";
// import "moment/locale/ar-ly";
// import "moment/locale/ar-ma";
// import "moment/locale/ar-sa";
// import "moment/locale/ar";
// import "moment/locale/az";
// import "moment/locale/be";
// import "moment/locale/bg";
// import "moment/locale/bn";
// import "moment/locale/br";
// import "moment/locale/bs";
// import "moment/locale/ca";
// import "moment/locale/cs";
// import "moment/locale/cv";
// import "moment/locale/cy";
// import "moment/locale/da";
// import "moment/locale/de-at";
// import "moment/locale/de-ch";
// import "moment/locale/de";
// import "moment/locale/el";
// import "moment/locale/en-au";
// import "moment/locale/en-ca";
// import "moment/locale/en-ie";
// import "moment/locale/en-in";
// import "moment/locale/en-nz";
// import "moment/locale/eo";
// import "moment/locale/es-do";
// import "moment/locale/es-mx";
// import "moment/locale/es-us";
// import "moment/locale/es";
// import "moment/locale/et";
// import "moment/locale/eu";
// import "moment/locale/fa";
// import "moment/locale/fi";
// import "moment/locale/fr-ca";
// import "moment/locale/fr-ch";
// import "moment/locale/fr";
// import "moment/locale/fy";
// import "moment/locale/ga";
// import "moment/locale/gd";
// import "moment/locale/gl";
// import "moment/locale/gu";
// import "moment/locale/he";
// import "moment/locale/hi";
// import "moment/locale/hu";
// import "moment/locale/hy-am";
// import "moment/locale/id";
// import "moment/locale/is";
// import "moment/locale/it-ch";
// import "moment/locale/it";
// import "moment/locale/ja";
// import "moment/locale/ka";
// import "moment/locale/kk";
// import "moment/locale/km";
// import "moment/locale/kn";
// import "moment/locale/ko";
// import "moment/locale/ky";
// import "moment/locale/lb";
// import "moment/locale/lt";
// import "moment/locale/lv";
// import "moment/locale/mi";
// import "moment/locale/mk";
// import "moment/locale/ml";
// import "moment/locale/mr";
// import "moment/locale/ms-my";
// import "moment/locale/ms";
// import "moment/locale/mt";
// import "moment/locale/nb";
// import "moment/locale/nl-be";
// import "moment/locale/nl";
// import "moment/locale/nn";
// import "moment/locale/oc-lnc";
// import "moment/locale/pa-in";
// import "moment/locale/pl";
// import "moment/locale/pt-br";
// import "moment/locale/pt";
// import "moment/locale/ro";
// import "moment/locale/sd";
// import "moment/locale/sk";
// import "moment/locale/sl";
// import "moment/locale/sq";
// import "moment/locale/sr-cyrl";
// import "moment/locale/sr";
// import "moment/locale/sv";
// import "moment/locale/sw";
// import "moment/locale/tr";
// import "moment/locale/uk";
// import "moment/locale/ur";
// import "moment/locale/vi";
// import "moment/locale/zh-cn";
// import "moment/locale/zh-hk";
// import "moment/locale/zh-mo";
// import "moment/locale/zh-tw";
// import Loading from "./shared/Loading/Loading.jsx";
// // import App from "./App.jsx";
// const App = lazy(() => import('./App.jsx'));

// const rootElement = document.getElementById("root");
// const helmetContext = {};


//   hydrate(<Suspense fallback={<Loading />}>
//   <HelmetProvider context={helmetContext}>
//     <App />
//   </HelmetProvider>
// </Suspense>, rootElement);

// reportWebVitals();

//src/index.js
import { hydrate } from "react-dom";
import "./i18n.js";
import 'react-dates/lib/initialize.js';
import "react-dates/lib/css/_datepicker.css";
import { HelmetProvider } from 'react-helmet-async';
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.min.css";
import { lazy, Suspense } from "react";
import Loading from "./shared/Loading/Loading.jsx";

const App = lazy(() => import('./App.jsx'));
const helmetContext = {};

const rootElement = document.getElementById("root");
hydrate(
  <Suspense fallback={<Loading />}>
    <HelmetProvider context={helmetContext}>
      <App />
    </HelmetProvider>
  </Suspense>,
  rootElement
);
