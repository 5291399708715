// import i18n from "i18next";
// import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// i18n
//   .use(initReactI18next)
//   .use(Backend)
//   .use(LanguageDetector)
//   .init({
//     react: { useSuspense: false },
//     debug: false,
//     fallbackLng: "en",
//     backend: {
//       loadPath: (lng, ns) => {
//         const isDev = process.env.NODE_ENV === 'development';
//         return isDev 
//           ? `/locales/${lng}/${ns}.json`
//           : `https://vacbid.com/locales/${lng}/${ns}.json`;
//       }
//     },
//     detection: {
//       order: ['path', 'navigator'],
//       lookupFromPathIndex: 0,
//     },
//     interpolation: {
//       escapeValue: false // XSS koruması için (React zaten güvenli hale getiriyor)
//     },
//     saveMissing: false // Eksik çevirileri kaydetmek istemiyorsanız kapatın
//   });
//src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const isBrowser = typeof window !== 'undefined';

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: 'en',
    backend: {
      loadPath: isBrowser 
        ? '/locales/{{lng}}/{{ns}}.json'
        : 'https://vacbid.com/locales/{{lng}}/{{ns}}.json'
    },
    detection: {
      order: ['path', 'cookie', 'navigator','localStorage'],
      lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false, // XSS ataklarına karşı
    }
  });

export default i18n;






